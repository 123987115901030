<template>
  <div id="builder-container">
    <!-- Toolbox (left side) -->
    <div id="toolbox">
      <div
        v-for="tool in tools"
        :key="tool.type"
        class="tool"
        draggable="true"
        @dragstart="onToolDragStart($event, tool)"
      >
        {{ tool.label }}
      </div>
    </div>

    <!-- Design Area (center) -->
    <div id="design-area">
      <button @click="addRow">Add Row</button>
      <div
        v-for="(row, rowIndex) in rows"
        :key="row.id"
        class="row"
      >
        <div
          v-for="(col, colIndex) in row.columns"
          :key="col.id"
          class="column"
          @dragover.prevent
          @drop="onDrop(rowIndex, colIndex, $event)"
        >
          <!-- Render components in this column -->
          <div
            v-for="(comp, compIndex) in col.components"
            :key="comp.id"
            class="component"
            :class="{ selected: selectedComponent && selectedComponent.id === comp.id }"
            @click="selectComponent(comp)"
          >
            <strong>{{ comp.type }}</strong> content
          </div>
        </div>
      </div>
      <button @click="exportDesign">Export HTML</button>
    </div>

    <!-- Properties Panel (right side) -->
    <div id="properties-panel">
      <h3>Properties</h3>
      <div v-if="selectedComponent">
        <!-- Collapsible section: Typography -->
        <div class="prop-section">
          <div class="prop-header" @click="toggleSection('typography')">
            Typography
          </div>
          <div class="prop-body" v-show="sections.typography">
            <label>
              Font Family:
              <input type="text" v-model="selectedComponent.style.fontFamily" />
            </label>
            <br />
            <label>
              Font Size:
              <input type="number" v-model.number="selectedComponent.style.fontSize" />
            </label>
          </div>
        </div>
        <!-- Collapsible section: Colours -->
        <div class="prop-section">
          <div class="prop-header" @click="toggleSection('colours')">
            Colours
          </div>
          <div class="prop-body" v-show="sections.colours">
            <label>
              Text Color:
              <input type="color" v-model="selectedComponent.style.color" />
            </label>
            <br />
            <label>
              Background Color:
              <input type="color" v-model="selectedComponent.style.backgroundColor" />
            </label>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Select a component to edit its properties.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailBuilder',
  data() {
    return {
      // Define toolbox items
      tools: [
        { type: 'header', label: 'Header' },
        { type: 'paragraph', label: 'Paragraph' },
        { type: 'image', label: 'Image' },
        { type: 'table', label: 'Table' },
        { type: 'button', label: 'Button' }
        // More tools can be added here
      ],
      // Array of rows in the design area
      rows: [],
      // For generating unique IDs
      nextId: 1,
      // The currently selected component (for editing in the properties panel)
      selectedComponent: null,
      // State for collapsible sections in the properties panel
      sections: {
        typography: true,
        colours: true
      }
    };
  },
  methods: {
    // Called when dragging a toolbox item; store its type in dataTransfer
    onToolDragStart(event, tool) {
      event.dataTransfer.setData('toolType', tool.type);
    },
    // Adds a new row with a default layout of 2 columns
    addRow() {
      const row = {
        id: this.nextId++,
        columns: []
      };
      // Create 2 columns by default (you could make this dynamic later)
      for (let i = 0; i < 2; i++) {
        row.columns.push({
          id: this.nextId++,
          components: []
        });
      }
      this.rows.push(row);
    },
    // Called when a toolbox item is dropped onto a column
    onDrop(rowIndex, colIndex, event) {
      const toolType = event.dataTransfer.getData('toolType');
      if (!toolType) return;
      // Create a new component with default style properties
      const component = {
        id: this.nextId++,
        type: toolType,
        style: {
          fontFamily: '',
          fontSize: 16, // default size (in pixels)
          color: '#000000',
          backgroundColor: '#ffffff'
        }
      };
      this.rows[rowIndex].columns[colIndex].components.push(component);
    },
    // Select a component so its properties are shown in the panel
    selectComponent(comp) {
      this.selectedComponent = comp;
    },
    // Toggle the visibility of a collapsible section
    toggleSection(section) {
      this.sections[section] = !this.sections[section];
    },
    // Export the design as HTML
    exportDesign() {
      let html = '';
      this.rows.forEach(row => {
        html += `<div class="row" style="display:flex;">`;
        row.columns.forEach(col => {
          html += `<div class="column" style="flex:1; border:1px solid #ddd; margin:5px;">`;
          col.components.forEach(comp => {
            // Inline the style properties
            const style = Object.entries(comp.style)
              .map(([key, value]) => `${key}:${value}${key==='fontSize'?'px':''};`)
              .join(' ');
            html += `<div class="component" data-type="${comp.type}" style="${style}"><strong>${comp.type}</strong> content</div>`;
          });
          html += `</div>`;
        });
        html += `</div>`;
      });
      console.log('Exported HTML:', html);
      alert('Exported HTML is logged in the console.');
    }
  }
};
</script>

<style scoped>
/* Container layout */
#builder-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  font-family: sans-serif;
}

/* Toolbox (left side) */
#toolbox {
  width: 200px;
  background: #f4f4f4;
  border-right: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
}
.tool {
  padding: 8px;
  margin-bottom: 8px;
  background: #fff;
  border: 1px solid #ccc;
  cursor: grab;
  text-align: center;
}

/* Design area (center) */
#design-area {
  flex: 1;
  padding: 10px;
  background: #fafafa;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.row {
  margin-bottom: 10px;
  border: 1px dashed #aaa;
  padding: 5px;
}
.column {
  border: 1px solid #ddd;
  margin: 5px;
  min-height: 80px;
  position: relative;
}
.component {
  padding: 10px;
  border: 1px solid #666;
  background: #fff;
  margin: 5px;
  cursor: pointer;
}
.component.selected {
  border-color: red;
}

/* Properties panel (right side) */
#properties-panel {
  width: 300px;
  background: #f4f4f4;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.prop-section {
  margin-bottom: 15px;
  border: 1px solid #ccc;
  background: #fff;
}
.prop-header {
  padding: 5px;
  background: #ddd;
  cursor: pointer;
}
.prop-body {
  padding: 5px;
}
</style>
